.front-background-container {
    position: relative;
    width: 100%;
    height: 100vh;
}

.background-image {
    background-image: url('https://csh.rit.edu/~jays/bkgrnd2.jpg');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
}

.overlay-text {
    position: absolute;
    top: 50%; /* Position the text vertically centered */
    left: 50%; /* Position the text horizontally centered */
    transform: translate(-50%, -50%); /* Center the text precisely */
    text-align: center;
    color: #ffffff;
}

.overlay-text h1 {
    font-size: 36px;
    font-weight: bold;
}
