.content-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: #ffffff; 
}

section {
    margin-bottom: 20px;
    text-align: center;
}

h2 {
    font-size: 36px;
    font-weight: bold;
}

p {
    font-size: 18px;
}

.content-container {
    padding: 20px;
    color: #ffffff;
}

.title {
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    margin-top: 30px; 
    color: #dfdcdc;
}

.section {
    margin-bottom: 30px;
}

.photo-displayer-container {
    margin-top: 3300px; 
}


.subtitle {
    font-size: 18px;
    color: #666;
}

body {
    background-color: rgb(36, 29, 29);
    font: 16px Arial, sans-serif;
    color: #ffffff;
}

.neutral-background {
    background-color: #f0f0f0; 
}

.button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #0056b3;
}
